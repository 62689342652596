import useWindowDimensions from "@lib/hooks/useWindowDimensions";
import Image from "next/image";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "@styles/cms_styles/carousel.module.scss";
import { FaInstagram } from "react-icons/fa";

interface Settings {
  arrows: boolean;
  dots: boolean;
  infinite: boolean;
  autoplay: boolean;
  speed: number;
  autoplaySpeed: number;
  cssEase: string;
  width: number;
  height: number;
  variableWidth: boolean;
  slidesToScroll: number;
}

export interface Gallery {
  settings: Settings;
  icon: string;
  gallery: Array<any>;
}

export default function CarouselGallery({ settings, icon, gallery }: Gallery) {
  const cg_settings: Settings = {
    ...settings,
    slidesToScroll: 1,
  };

  const settingsLargeDesktop = {
    ...cg_settings,
    slidesToShow: 3,
  };

  const settingsTablet = {
    ...cg_settings,
    slidesToShow: 2,
  };

  const settingsMobile = {
    ...cg_settings,
    slidesToShow: 1,
  };

  const { width, height } = useWindowDimensions();
  if (!gallery) return null;

  const slickSlider = (settings: any) => {
    return (
      <Slider {...settings}>
        {gallery.map((item, i) => (
          <div key={i}>
            {item.image ? (
              <>
                <Image
                  className={styles.carouselImage}
                  src={item.image}
                  alt={item.alt}
                  aria-hidden="true"
                  width={settings.width}
                  height={settings.height}
                />
                {settings.instagram && (
                  <div className={styles.infoContainer}>
                    <FaInstagram className={styles.instagramIcon} />
                    <p className={styles.instagramHandle}>@{item.alt}</p>
                  </div>
                )}
              </>
            ) : (
              <div className={styles.carouselText} key={i}>
                <p>
                  {item.alt}
                  <span style={{ backgroundImage: `url(${icon})` }}></span>
                </p>
              </div>
            )}
          </div>
        ))}
      </Slider>
    );
  };

  if (width && width >= 1024)
    return (
      <div className={styles.carouselGallery}>
        {slickSlider(settingsLargeDesktop)}
      </div>
    );
  if (width && width >= 641 && width < 1024)
    return (
      <div className={styles.carouselGallery}>
        {slickSlider(settingsTablet)}
      </div>
    );
  if (width && width < 641)
    return (
      <div className={styles.carouselGallery}>
        {slickSlider(settingsMobile)}
      </div>
    );

  return <></>;
}
